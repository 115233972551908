<template>
  <div class="home">
    <Header />
    <Hero />

    <section>
      <div class="container mt-5">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="box live p-4" style="text-align: center">
              <img src="@/assets/images/live.svg" alt="Canlı Yayın" />
              <h2 class="title red text-center">Oturum Kayıtları</h2>
              <router-link to="/recordings" class="btn btn-danger btn-md mt-2"
                >İzlemek İçin Tıklayın</router-link
              >
              <!--
              <span>SIRADAKİ OTURUM</span>
              <h2 class="mt-4">
                MVP, Nasıl Tanı Konulur?<br />3D EKO Ne Katkı Sağlar?
              </h2>
              <p class="pt-3 pb-3">
                Dr. Hani Mahmuod Elsayed<br />
                <span
                  >Al-Nas Hospital, Shubra Al-Kheimah, Al-Qalyubia Kahire</span
                >
              </p>
              <router-link to="/live" class="btn btn-info btn-md mt-2"
                >CANLI İZLE</router-link
              >
              -->
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="box p-4">
              <h2 class="title blue text-center">Katılım Sertifikası</h2>
              <p style="text-align: center">
                Sertifikalarınızı 5 Eylül, 18:00 itibari ile buradan
                indirebilirsiniz.
              </p>
              <!--
              <router-link
                class="btn btn-primary btn-md btn-block"
                to="/certificate"
                tag="button"
                :disabled="!certificateEnabled"
                >SERTİFİKA İÇİN TIKLAYIN</router-link
              >
              -->
              <b-button
                class="btn btn-primary btn-md btn-block"
                variant="primary"
                @click="downloadCert()"
                :disabled="!certificateEnabled"
                v-if="!isCertDownloading"
              >
                SERTİFİKA İÇİN TIKLAYIN
              </b-button>

              <b-button
                variant="primary"
                class="btn-md mt-3"
                disabled
                block
                size="lg"
                v-if="isCertDownloading"
              >
                <b-spinner small></b-spinner>
                Sertifika Hazırlanıyor....
              </b-button>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 offset-md-3">
            <div class="box p-4">
              <h2 class="title blue text-center">Poster Oturumu</h2>
              <b-button
                class="btn btn-primary btn-md btn-block"
                variant="primary"
                href="https://livecast.box.com/s/bwxahkmjwg9z9rxci6m9mdzze9l84qla"
                target="_blank"
              >
                Poster için tıklayın
              </b-button>
            </div>
          </div>
          <div class="col-md-12">
            <div class="box">
              <img
                src="@/assets/images/nestle_event.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
          <!--
          <div class="col-md-12">
            <div class="box">
              <img
                src="@/assets/images/960x360px.jpg"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="col-xs-12 col-md-6 offset-md-3">
            <div class="box">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://player.vimeo.com/video/551691390"
                  width="640"
                  height="480"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </section>
    <BoothList />
    <!--<Sponsors />-->
    <!--<Expo />-->
    <Agenda />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Hero from '@/components/Hero.vue';
import Agenda from '@/components/Agenda.vue';
import BoothList from '@/components/BoothList.vue';
// import Sponsors from '@/components/Sponsors.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { activateCertificate } from '@/config';

export default {
  name: 'Home',
  components: {
    Header,
    Agenda,
    // Sponsors,
    Hero,
    BoothList,
  },
  data() {
    return {
      isReady: false,
      certificateEnabled: false,
      isCertDownloading: false,
    };
  },
  computed: {
    ...mapGetters({
      liveSession: 'agenda/liveSession',
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrent: 'event/setCurrent',
      getEvent: 'event/get',
      getCertificate: 'people/getCertificateUrl',
    }),
    tabClick() {},
    goBack() {
      this.$router.push('/');
    },
    enableCertificate() {
      this.certificateEnabled = false;
      const sesTime = moment();
      const enableTime = moment(activateCertificate, 'DD MM YYYY hh:mm');
      console.log(enableTime);
      console.log(sesTime);

      if (enableTime.isBefore(sesTime)) {
        this.certificateEnabled = true;
      } else {
        this.certificateEnabled = false;
      }
      console.log(this.certificateEnabled);
    },
    downloadCert() {
      this.isCertDownloading = true;
      this.getCertificate().then((res) => {
        this.isCertDownloading = false;
        console.log(res);
      });
    },
  },
  created() {
    this.enableCertificate();
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.live {
    h2 {
      color: #dc3545;
    }
  }
}
</style>
